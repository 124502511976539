import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { errorNotification } from 'application/notification';
import { roundMoney } from 'application/utils/money';

import GET_BALANCE_FINE_INFO, {
  GetBalanceFineInfo,
  GetBalanceFineInfoVars,
} from '../graphql/get-balance-fine-info';

export const useBalanceFineInfo = () => {
  const [balanceFineInfo, setBalanceFineInfo] = useState<null | {
    currentVotes: number;
    startBalance: number;
    needVotesAmount: number;
    burnAmount: number;
  }>(null);

  const { data, error } = useQuery<GetBalanceFineInfo, GetBalanceFineInfoVars>(
    GET_BALANCE_FINE_INFO,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (error) {
      errorNotification(error.message);
    }

    if (data?.getBalanceFineInfo.__typename === 'Error') {
      errorNotification(data.getBalanceFineInfo.error);
    }

    if (data?.getBalanceFineInfo.__typename === 'GetBalanceFineInfo') {
      setBalanceFineInfo({
        currentVotes: roundMoney(
          parseFloat(data.getBalanceFineInfo.currentVotes)
        ),
        startBalance: roundMoney(
          parseFloat(data.getBalanceFineInfo.startBalance)
        ),
        needVotesAmount: roundMoney(
          parseFloat(data.getBalanceFineInfo.needVotesAmount)
        ),
        burnAmount: roundMoney(parseFloat(data.getBalanceFineInfo.burnAmount)),
      });
    }
  }, [data, error]);

  return balanceFineInfo;
};

export default useBalanceFineInfo;
