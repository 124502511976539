import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface GetBalanceFineInfo {
  getBalanceFineInfo:
    | {
        __typename: 'GetBalanceFineInfo';
        startBalance: string;
        currentVotes: string;
        needVotesAmount: string;
        burnAmount: string;
      }
    | GraphqlErrorType;
}

export interface GetBalanceFineInfoVars {}

const GET_BALANCE_FINE_INFO = gql`
  query GetBalanceFineInfo {
    getBalanceFineInfo {
      __typename
      ... on GetBalanceFineInfo {
        startBalance
        currentVotes
        needVotesAmount
        burnAmount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_BALANCE_FINE_INFO;
