import React from 'react';
import { useTranslation } from 'react-i18next';

import { roundNumber } from 'application/utils/numbers';
import { CircleProgressBar } from 'ui';

import css from './balance-fine-notification.module.scss';

interface BalanceRuleNotificationProps {
  currentVotes: number;
  startBalance: number;
  needVotesAmount: number;
  burnAmount: number;
}

const BalanceRuleNotification: React.FC<BalanceRuleNotificationProps> = ({
  currentVotes,
  startBalance,
  needVotesAmount,
  burnAmount,
}) => {
  const { t } = useTranslation(['account']);

  let progress =
    needVotesAmount > 0
      ? roundNumber((currentVotes / needVotesAmount) * 100, 0)
      : 100;
  if (progress > 100) {
    progress = 100;
  }

  return (
    <div className={css.Root}>
      <CircleProgressBar progress={progress} />
      {progress === 100 ? (
        <div
          className={css.Notification}
          dangerouslySetInnerHTML={{
            __html: t('account:no_burn_notification'),
          }}
        />
      ) : (
        <div
          className={css.Notification}
          dangerouslySetInnerHTML={{
            __html: t('account:burn_notification', {
              startBalance,
              currentVotes,
              needVotesAmount,
              burnAmount,
            }),
          }}
        />
      )}
    </div>
  );
};

export default BalanceRuleNotification;
