import { useTranslation } from 'react-i18next';

import { Button } from 'ui';
import { Flex } from 'ui/flex';
import { Text } from 'ui/v2/typography/text';

import { WalletAddress } from '../../wallets-types';

export interface WalletsListProps {
  walletEnabled: boolean;
  account: string;
  wallets: WalletAddress[];
  limits: Record<string, number>;
  processing: boolean;
  onRemove: (id: string) => void;
  onApprove: () => void;
  onRevoke: () => void;
}

const WalletsList: React.FC<WalletsListProps> = ({
  account,
  processing,
  wallets,
  limits,
  walletEnabled,
  onRemove,
  onApprove,
  onRevoke,
}) => {
  const { t } = useTranslation(['wallets']);

  if (wallets.length === 0) {
    return null;
  }

  return (
    <>
      {wallets.map((wallet) => (
        <Flex key={wallet.address} style={{ overflowWrap: 'anywhere' }}>
          {wallet.address}
          {walletEnabled && ` (${limits[wallet.address] || 0} vts)`}
          {walletEnabled && account === wallet.address && (
            <>
              &nbsp;
              <Button onClick={onApprove} size="small" outline filled>
                {t('approve')}
              </Button>
            </>
          )}
          {walletEnabled &&
            account === wallet.address &&
            limits[wallet.address] > 0 && (
              <>
                &nbsp;
                <Button onClick={onRevoke} size="small" outline filled>
                  {t('revoke')}
                </Button>
              </>
            )}
          &nbsp;
          <Button
            onClick={() => onRemove(wallet.id)}
            size="small"
            disabled={processing}
            outline
          >
            {t('remove')}
          </Button>
        </Flex>
      ))}
    </>
  );
};

export default WalletsList;
