import React from 'react';

import { Loader } from 'ui';

import BalanceRuleNotification from './balance-fine-notification';
import useBalanceFineInfo from '../../hooks/use-get-balance-fine-info';

const BalanceRuleNotificationContainer = () => {
  const data = useBalanceFineInfo();

  if (!data) {
    return <Loader />;
  }

  return (
    <BalanceRuleNotification
      currentVotes={data.currentVotes}
      startBalance={data.startBalance}
      needVotesAmount={data.needVotesAmount}
      burnAmount={data.burnAmount}
    />
  );
};

export default BalanceRuleNotificationContainer;
